<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="$router.go(-1)"
        style="margin-right: 16px"
      >
        <i class="t-transition icons icon-chevron-left" />
        {{ $t("back") }}
      </a-button>
      <!-- <h1>{{ $route.meta.title[$i18n.locale] }}</h1> -->
      <h1>
        {{
          (menuDetail && menuDetail.name && menuDetail.name[$i18n.locale]) || ""
        }}
      </h1>
      <a-row class="post-search" type="flex">
        <a-input
          v-model="search"
          class="search"
          :placeholder="$t('search') + '...'"
          @input="SearchInput"
        />
        <a-button type="primary" @click="createFunc()">
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>
    </a-row>
    <a-row type="flex" class="table__filtrs">
      <!-- <h1>{{ $t(post_group) }}</h1> -->
      <h1>Комиссия ваколатлари</h1>
      <a-select
        v-model="perPage"
        class="table__parpage"
        option-filter-prop="children"
        placeholder="Все"
        @change="tableperPage"
      >
        <a-select-option
          v-for="(limit, index) in LimitList"
          :key="index"
          :value="limit.value"
        >
          {{ limit.title }}
        </a-select-option>
      </a-select>
      <!-- <a-button type="primary" class="all-news-btn" @click="Allnews">
        {{ $t("allNews") }}
      </a-button> -->
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 0 10px"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        {{ $t("Delete") }}
      </a-button>
    </a-row>
    <!-- :scroll="{ x: 1200 }" -->
    <a-table
      style="overflow-x: auto"
      :columns="columns"
      :data-source="categoryProps.results || []"
      :loading="loading"
      :pagination="false"
    >
      <div
        slot="news_title"
        slot-scope="item"
        class="td-post-item"
        @click="
          $router.push({
            name: 'senat-commissions-posts-update',
            params: { menu_id: $route.params.menu_id, id: item.id },
            query: { post_group: post_group },
          })
        "
      >
        <b style="cursor: pointer">{{ item.title }}</b>
        <br />
        <!-- <a-tag v-if="item.title">
          <img
            width="14"
            class="flag-svg__tab"
            :src="
              contentLanguages[getFirstFulfilled(item.title).lang].flagSrc || ''
            "
            :alt="getFirstFulfilled(item.title).lang"
          />
          {{ contentLanguages[getFirstFulfilled(item.title).lang].abr }}
        </a-tag> -->
      </div>

      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template>

      <template slot="author" slot-scope="item">
        {{ item.author || "" }}
      </template>

      <template v-if="item.title" slot="title_languages" slot-scope="item">
        <template v-for="(title, key) in item.title_languages">
          <a-tag v-if="title" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[title].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[title].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="content_languages" slot-scope="item">
        <template v-for="(content, idx) in item.content_languages">
          <a-tag :key="idx">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[content].flagSrc || ''"
              :alt="idx"
            />
            {{ contentLanguages[content].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="created" slot-scope="item">
        {{ moment(item.pub_date).format("LLL") }}
      </template>

      <template slot="files" slot-scope="item">
        {{ (item.images && item.images.length) || " - " }}
      </template>

      <template slot="position" slot-scope="item">
        {{ item.position }}
      </template>

      <template v-if="item" slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="language_support" slot-scope="item">
        <template v-for="(languageSupport, key) in item.language_support">
          <a-tag v-if="languageSupport" :key="key">
            <img
              width="14"
              class="flag-svg__tab"
              :src="contentLanguages[key].flagSrc || ''"
              :alt="key"
            />
            {{ contentLanguages[key].abr }}
          </a-tag>
        </template>
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-switch
          v-model="item.status"
          style="margin-right: 15px"
          @change="checkActive(item, $event)"
        />
        <a-tag :color="item.status ? 'geekblue' : 'volcano'">
          {{ item.status ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template v-if="'is_main' in item" slot="is_main" slot-scope="item">
        <a-tag :color="item.is_main ? 'geekblue' : 'volcano'">
          {{ item.is_main ? $t("On") : $t("Off") }}
        </a-tag>
      </template>

      <template v-if="'on_slider' in item" slot="to_slider" slot-scope="item">
        <a-switch
          v-model="item.on_slider"
          style="margin-right: 15px"
          @change="checkSlider(item, $event)"
        />
        <a-badge
          :status="item.on_slider ? 'success' : 'error'"
          :text="item.on_slider ? $t('Yes') : $t('No')"
        />
      </template>

      <template v-if="item && 'views' in item" slot="views" slot-scope="item">
        <a-icon type="eye" />
        {{ item.views }}
      </template>

      <template
        v-if="item && 'files' in item"
        slot="files_size"
        slot-scope="item"
      >
        {{
          bytesToSize(Object.values(item.files).reduce((a, b) => a + b.size, 0))
        }}
      </template>

      <template slot="operation" slot-scope="item">
        <!-- <a-icon
          class="action-btns"
          type="edit"
          @click="
            $router.push(
              { 
                name: 'post-update',
                params: { id: item.id },
                query: { menu: $route.params.id }
              },
              () => null
            )
          "
        /> -->
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({
              name: 'senat-commissions-posts-update',
              params: { menu_id: $route.params.menu_id, id: item.id },
              query: { post_group: post_group },
            })
          "
        >
          {{ $t("Edit") }}
          <a-icon type="edit" class="action-btns" />
        </a-button>
        <!-- <a-popconfirm
          :cancel-text="$t('No')"
          :ok-text="$t('Yes')"
          :title="$t('APopconfirm')"
          @confirm="removeItem(item)"
        >
          <a-icon class="action-btns" style="margin: 0 10px" type="delete" />
        </a-popconfirm> -->
      </template>
    </a-table>
    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+categoryProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
import { CONTENT_LANGUAGES } from "@/constants";

export default {
  data() {
    return {
      post_group: "",
      menuDetail: null,
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          // title: this.$t("TableHead"),
          // width: "400px",
          key: "id",
          scopedSlots: { customRender: "id" },
          fixed: "left",
        },
        {
          title: this.$t("TableHead"),
          width: 400,
          key: "news_title",
          scopedSlots: { customRender: "news_title" },
          fixed: "left",
        },
        {
          title: this.$t("headerLang"),
          key: "title_languages",
          width: 190,
          scopedSlots: { customRender: "title_languages" },
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: 220,
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.$t("LangContent"),
          key: "content_languages",
          width: 190,
          scopedSlots: { customRender: "content_languages" },
        },
        {
          title: this.$t("Created"),
          key: "created",
          width: 180,
          scopedSlots: { customRender: "created" },
        },
        // {
        //   title: "Просмотры",
        //   key: "views",
        //   width: 150,
        //   scopedSlots: { customRender: "views" }
        // },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          fixed: "right",
          // width: "120px",
          scopedSlots: { customRender: "operation" },
        },
      ],
      search: "",
      categoryProps: {
        results: [],
        count: 0,
      },
      LimitList: [
        // {
        //   value: 0,
        //   title: "Все"
        // },
        {
          value: 10,
          title: "10",
        },
        {
          value: 20,
          title: "20",
        },
        {
          value: 40,
          title: "40",
        },
        {
          value: 50,
          title: "50",
        },
        {
          value: 100,
          title: "100",
        },
        {
          value: 150,
          title: "150",
        },
        {
          value: 200,
          title: "200",
        },
      ],
      cardsIsEmpty: true,
      perPage: 20,
      page: 1,
      offset: 0,
    };
  },
  computed: {
    contentLanguages: () => CONTENT_LANGUAGES,
  },
  created() {
    this.post_group = (this.$route.query && this.$route.query.post_group) || "";
  },
  // watch: {
  //   $route: {
  //     handler: "fetchData",
  //     immediate: true,
  //   },
  // },
  async mounted() {
    await this.fetchMenuDetail();
    await this.fetchData();
  },
  methods: {
    async checkActive(item, event) {
      await this.$api.put(`/admin/post/${item.id}`, {
        status: event,
      });
    },
    async checkSlider(item, event) {
      await this.$api.patch(`/admin/post/${item.id}/update/`, {
        on_slider: event,
      });
    },
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item == +itemId);
      if (f != -1) {
        this.selectedPosts.splice(f, 1);
        this.cardsIsEmpty = false;
      } else {
        this.selectedPosts.push(itemId);
        this.cardsIsEmpty = false;
      }

      if (this.selectedPosts.length == 0) {
        this.cardsIsEmpty = true;
      }
      console.log(this.selectedPosts);
    },
    tableperPage() {
      this.offset = 0;
      this.fetchData();
    },
    async Allnews() {
      this.loading = true;
      this.perPage = null;
      this.categoryProps = {
        results: [],
        count: 0,
      };
      try {
        const r = await this.$store.dispatch("posts/fetch", {
          menu: this.$route.params.menu_id,
        });
        if (r) {
          this.$set(this, "categoryProps", {
            results: r.data.results || [],
            count: r.data.count,
          });
        }
      } catch (e) {
        this.$sentry.captureMessage(e);
      }
      this.loading = false;
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } });
    },
    SearchInput() {
      this.offset = 0;
      this.fetchData();
    },
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "n/a";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    getFirstFulfilled(fields) {
      let lang = this.$i18n.locale;
      if (!fields) return {};
      if (fields[lang]) {
        return {
          text: fields[lang],
          lang: lang,
        };
      } else {
        const lang = Object.keys(fields).find((l) => fields[l]);
        if (!lang) {
          return {};
        }
        return {
          text: fields[lang],
          lang: lang,
        };
      }
    },
    async fetchMenuDetail() {
      let menu_id = +this.$route.params.menu_id;

      try {
        const res = await this.$store.dispatch("menu/detail", menu_id);
        if (res) {
          this.menuDetail = res.data;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchData() {
      this.loading = true;
      this.offset = +this.$route.query.page || 1;
      this.page = this.offset;
      this.offset = (this.offset - 1) * this.perPage;
      try {
        const r = await this.$store.dispatch("posts/fetch", {
          menu_id: this.$route.params.menu_id,
          post_group: this.post_group,
          limit: this.perPage,
          offset: this.offset,
          search: this.search,
        });
        if (r) {
          this.$set(this, "categoryProps", {
            results: r.data.results || [],
            count: r.data.count,
          });
        }
      } catch (e) {
        this.$sentry.captureMessage(e);
      }
      this.loading = false;
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const { data } = await this.$store.dispatch("posts/deletePost", i);
            if (data && data.status) {
              this.$message.success("Успешно удаленно");
              this.fetchData();
            }
          } catch (e) {
            this.$sentry.captureMessage(e);
          }
        }
      } else {
        try {
          const res = await this.$store.dispatch("posts/deletePost", id);
          if (res && res.status == 204) {
            this.$message.success("Новости успешно удаленно");
            this.fetchData();
          }
        } catch (e) {
          this.$sentry.captureMessage(e);
        }
      }
      this.selectedCards = [];
      this.cardsIsEmpty = true;
    },
    async createFunc() {
      // let parent = null
      let res = await this.$api.post("/admin/post/create", {
        menu_id: +this.$route.params.menu_id,
        post_group: this.post_group,
        status: true,
      });
      console.log(res.data);
      if (res.data && res.data.status) {
        this.$router.push({
          name: "senat-commissions-posts-update",
          query: {
            post_group: this.post_group,
          },
          params: {
            menu_id: +this.$route.params.menu_id,
            id: res && res.data && res.data.data.id,
          },
        });
      }
    },
  },
};
</script>
